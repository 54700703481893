var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingData,
              expression: "loadingData",
            },
          ],
          ref: "multipleTable",
          attrs: {
            "empty-text": _vm.textTableSetting,
            "element-loading-text": _vm.$tableLoading,
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(255,255,255, 0)",
            "highlight-current-row": "",
            data: _vm.cameraSettingList,
            "header-cell-style": _vm.$funcCommon.tableHeaderColorFunction,
            "max-height": _vm.$tableMaxHeight,
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "STT",
              width: "50",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "className", label: "Tên lớp", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "Danh sách camera" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.cameraList, function (item) {
                    return _c(
                      "el-tag",
                      {
                        key: item.id,
                        staticStyle: { "margin-right": "8px" },
                        attrs: { type: "", effect: "plain" },
                      },
                      [_vm._v(_vm._s(item.camName))]
                    )
                  })
                },
              },
            ]),
          }),
          _vm.checkPermission(["schoolConfig_cameraSchool_update"])
            ? _c("el-table-column", {
                attrs: { label: "Tác vụ", width: "150", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.addCameraForClassMethod(scope.row)
                                },
                              },
                            },
                            [_vm._v("Thêm Camera")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2976573935
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("AddCameraForClassSchoolDialog", {
        ref: "AddCameraForClassSchoolDialog",
        attrs: { dialogVisible: _vm.showAddCameraDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseAddCameraMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }